import { withAuthorizationHoc } from './HOCs/PackingAuthorizationHoc';
import { addNotesToAttributesListInDetails } from '../../common/utils';

import {
    PICKUP_CANCEL_ROUTE,
    PICKUP_DETAILS_ROUTE,
    PICKUP_PICKING_ROUTE,
} from '../../../../modules/fulfillment/consts';

import { ORDERS_DETAILS_ROUTE } from '../../../../modules/orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../../../../modules/products/consts';

export default {
    name: 'fulfillmentPickupPackingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'pickupPacking';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                infoProps: {
                    customRightAttributesList: addNotesToAttributesListInDetails,
                },
                // should be removed in 32.x.x core releases
                routes: {
                    detailsPageRoute: PICKUP_DETAILS_ROUTE,
                    pickingPageRoute: PICKUP_PICKING_ROUTE,
                    cancelPageRoute: PICKUP_CANCEL_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                    orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                },
                permissions: {
                    canAssignUser: true,
                    canUnassignUser: true,
                    canCancel: true,
                    withProductDetailsLink: true,
                    withOrderDetailsLink: true,
                },
            });

            pageRoute.render = withAuthorizationHoc();
            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
