import { withAuthorizationHoc } from './HOCs/PackingAuthorizationHoc';
import { addNotesToAttributesListInDetails } from '../../common/utils';

import {
    SHIP_PACK_ROUTE,
    SHIP_DETAILS_ROUTE,
    SHIP_PICKING_ROUTE,
    SHIP_PACKING_ROUTE,
} from '../../../../modules/fulfillment/consts';

import { ORDERS_DETAILS_ROUTE } from '../../../../modules/orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../../../../modules/products/consts';
import { SHIPMENT_DETAILS_ROUTE, SHIPMENT_PRINT_ROUTE } from '../../../../modules/shipment/consts';

export default {
    name: 'fulfillmentShipPackingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'fulfillmentShipPacking';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                itemsHideUntilParcelSelected: false,
                infoProps: {
                    customRightAttributesList: addNotesToAttributesListInDetails,
                },
                // should be removed in 32.x.x core releases
                routes: {
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                    orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                    pickingPageRoute: SHIP_PICKING_ROUTE,
                    packingPageRoute: SHIP_PACKING_ROUTE,
                    packPageRoute: SHIP_PACK_ROUTE,
                    detailsPageRoute: SHIP_DETAILS_ROUTE,
                    shipmentDetailsPageRoute: SHIPMENT_DETAILS_ROUTE,
                    shipmentPrintPageRoute: SHIPMENT_PRINT_ROUTE,
                },
                permissions: {
                    canAssignUser: true,
                    canUnassignUser: true,
                    canCancel: true,
                    withProductDetailsLink: true,
                    withOrderDetailsLink: true,
                },
            });

            pageRoute.render = withAuthorizationHoc();
            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
