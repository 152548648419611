import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';
import { actions as notification } from '@skunexus/notification';
import { ajaxExceptionMessage } from '@skunexus/utils';
import { Spinner } from '@skunexus/component-catalog';

import * as API from '../../../common/api';

export const withAuthorizationHoc = () => (Component) => {
    function WrappedComponent(props) {
        const { params, errorNotification = () => {} } = props;
        const [authorization, setAuthorization] = useState({
            isAuthorizing: false,
            isAuthorized: false,
        });

        const checkAuthorization = async ({ fulfillmentId = '' } = {}) => {
            setAuthorization((state) => ({
                ...state,
                isAuthorizing: true,
            }));

            try {
                await API.authorizePack({ fulfillmentId });
                setAuthorization((state) => ({
                    ...state,
                    isAuthorizing: false,
                    isAuthorized: true,
                }));
            } catch (e) {
                errorNotification(ajaxExceptionMessage(e));

                setAuthorization((state) => ({
                    ...state,
                    isAuthorizing: false,
                    isAuthorized: false,
                }));
            }
        };

        useEffect(() => {
            const { id } = params;
            if (id) {
                checkAuthorization({ fulfillmentId: id });
            }
        }, [params?.id]);

        if (authorization.isAuthorizing) {
            return <Spinner className="mt-3" />;
        }

        if (authorization.isAuthorized) {
            return <Component {...props} />;
        }

        return (
            <Component
                {...props}
                cartSectionComponent={() => null}
                packingFormComponent={() => (
                    <Alert color="danger" className="text-center">
                        Packing process is disabled for that fulfillment.
                    </Alert>
                )}
            />
        );
    }

    const mapDispatchToProps = {
        errorNotification: notification.error,
        successNotification: notification.success,
    };

    return connect(null, mapDispatchToProps)(WrappedComponent);
};
